import React, { useState, useEffect } from 'react'

export default function FichaMovimientosTabContent(props) {
	const { animalId } = props

	console.log('animalId', animalId)

	return (
		<div className="tab-content-wrapper">
			Historial movimientos
		</div>
	)
}