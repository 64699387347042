import React from 'react'
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Card from 'react-bootstrap/Card'
import Table from 'react-bootstrap/Table'
import Alert from 'react-bootstrap/Alert'
import BaseDataMostrar from '../BaseDataMostrar'

export default function IdentificacionVer(props) {
	const { config, tarea } = props

	const propietario = config.propietarios.find(propietario => propietario.id === tarea.identificacion.propietarioId)
	const establecimiento = config.establecimientos.find(establecimiento => establecimiento.id === tarea.identificacion.establecimientoId)
	const categoria = config.categorias.find(categoria => Number(categoria.id) === Number(tarea.identificacion.categoriaId))

	return (
		<Container>
			<Row>
				<Col>
					<h3>{tarea.nombre}</h3>
				</Col>
			</Row>
			{tarea.papa ? (
				<Row>
					<Col>
						<h5>{`Creado a partir de la tarea ${tarea.papa.nombre || ('#' + tarea.papa.id)} del tipo ${tarea.papa.tipo}`}</h5>
					</Col>
				</Row>
			) : (
				<BaseDataMostrar
					usuarios={config.usuarios}
					data={{
						nombre: tarea.nombre,
						fechaParaCuando: tarea.fechaParaCuando,
						asignadoId: tarea.asignadoId || '',
						observacion: tarea.observacion
					}}
				/>
			)}
			{tarea.comentarioEncargado && <Alert variant="info" className="mt-3">{tarea.comentarioEncargado}</Alert>}
			<Card className="mt-3">
				<Card.Body>
					<Card.Title>Resumen</Card.Title>
					<Row>
						<Col md="6">
							<Table>
								<tbody>
									<tr>
										<th>Total animales</th>
										<td>{tarea.identificacion.animales.length}</td>
									</tr>
									<tr>
										<th>Propietario</th>
										<td>{`${propietario.nombre} ${propietario.apellido}`}</td>
									</tr>
									<tr>
										<th>Establecimiento</th>
										<td>{establecimiento.nombre}</td>
									</tr>
									<tr>
										<th>Categoría</th>
										<td>{categoria.nombre}</td>
									</tr>
									<tr>
										<th>Estado</th>
										<td>{tarea.identificacion.estado}</td>
									</tr>
								</tbody>
							</Table>
						</Col>
					</Row>
				</Card.Body>
			</Card>
			<Card className="mt-3">
				<Card.Body>
					<Row>
						<Col md="6">
							<Card.Title>Total animales: <strong>{tarea.identificacion.animales.length}</strong></Card.Title>
							<Table>
								<thead>
									<tr>
										<th>#</th>
										<th>Núm. chip</th>
										<th>Núm. caravana</th>
										<th>Núm. tatuaje</th>
										<th>Carimbo</th>
										<th>Raza</th>
										<th>Sexo</th>
									</tr>
								</thead>
								<tbody>
									{tarea.identificacion.animales.map((animal, i) => {
										return (
											<tr key={animal.id}>
												<td>{i + 1}</td>
												<td>{animal['numChip'] || '-'}</td>
												<td>{animal['numCaravana'] || '-'}</td>
												<td>{animal['numTatuaje'] || '-'}</td>
												<td>{animal.carimbo || '-'}</td>
												<td>{animal.raza || '-'}</td>
												<td>{animal.sexo}</td>
											</tr>
										)
									})}
								</tbody>
							</Table>
						</Col>
					</Row>
				</Card.Body>
			</Card>
		</Container >
	)
}