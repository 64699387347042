import React, { useState, useEffect, useContext } from 'react'
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Form from 'react-bootstrap/Form'
import Alert from 'react-bootstrap/Alert'
import { LoadingSpinner } from 'components/LoadingSpinner'
import { FiltrosFormWrapper } from 'components/FiltrosFormWrapper'
import { ConfigContext } from 'contexts/config'
import { debounce } from 'utils/debounce'
import '../reportes.css'

import axios from 'axios'
import api from 'api/api'
import { FETCH_DE_A_CUANTOS, OPCIONES_DE_A_CUANTOS } from 'config/reportes'
import { getUpladsUrl } from 'utils/general'
import { SortableTable } from 'components/SortableTable'
import Paginacion from 'components/Paginacion'
import { getInicioAnho, getShortDisplayDate } from 'utils/date'
import { numberWithSeparator } from 'utils/numberWithSeparator'
import { MultiSelectCustom } from 'components/MultiSelectCustom'
import { SelectDateRange } from 'components/SelectRange'
import { InfoIcon } from 'components/InfoIcon'

export default function ReportePesajes() {
	const { data: config, loading: loadingConfig, error } = useContext(ConfigContext)

	const [cancelToken, setCancelToken] = useState(null)
	const [fetchDeACuantos, setFetchDeACuantos] = useState(FETCH_DE_A_CUANTOS)
	const [numResultados, setNumResultados] = useState(0)
	const [datos, setDatos] = useState([])
	const [paginaAct, setPaginaAct] = useState(1)
	const [loading, setLoading] = useState(true)
	const [filtros, setFiltros] = useState({
		propietarioId: [],
		establecimientoId: [],
		categoriaId: [],
		fecha: {
			inicio: getInicioAnho(),
			fin: new Date()
		},
		categoria: '',
		pesoGte: 0,
		pesoLte: 1000,
		orden: {
			campo: 'numCaravana',
			direccion: 'asc'
		}
	})
	const [cambioFiltros, setCambioFiltros] = useState(true)
	const [configLoaded, setConfigLoaded] = useState(false)
	const [loteNombre, setLoteNombre] = useState('')
	const [pesoGte, setPesoGte] = useState('0')
	const [pesoLte, setPesoLte] = useState('1000')

	const opcionesDeACuantos = OPCIONES_DE_A_CUANTOS
	const propietariosOptions = config.propietarios.map((e) => ({
		value: e.id,
		label: `${e.nombre} ${e.apellido}`,
	}))

	const establecimientosOptions = config.establecimientos.map((e) => ({
		value: e.id,
		label: e.nombre,
	}))

	const categoriasOptions = config.categorias.map((e) => ({
		value: e.id,
		label: e.nombre,
	}))

	const resultadoDesde = datos.length ? (paginaAct - 1) * fetchDeACuantos + 1 : 0
	const resultadoHasta = datos.length < fetchDeACuantos ? (resultadoDesde - 1 + datos.length) : resultadoDesde + fetchDeACuantos

	if (!cancelToken) {
		const source = axios.CancelToken.source()
		setCancelToken(source)
	}

	useEffect(() => {
		return () => {

			if (cancelToken) {
				cancelToken.cancel('Petición cancelada')
			}
		}
	}, [])

	useEffect(() => {
		if (!loadingConfig) {
			setConfigLoaded(true)
			setFiltros(prev => ({
				...prev,
				propietarioId: propietariosOptions,
				establecimientoId: establecimientosOptions,
				categoriaId: categoriasOptions
			}))
		}
	}, [loadingConfig])

	const setFiltroLoteNombre = (str) => {
		setFiltros(prev => ({
			...prev,
			loteNombre: str
		}))
	}

	const debouceFiltroLote = React.useCallback(
		debounce(setFiltroLoteNombre, 400),
		[]
	)

	useEffect(() => {
		if (!loading && loteNombre !== filtros.loteNombre) {
			debouceFiltroLote(loteNombre)
		}
	}, [loteNombre])

	const setFiltroPesoGte = (str) => {
		setFiltros(prev => ({
			...prev,
			pesoGte: str
		}))
	}

	const debouceFiltroPesoGte = React.useCallback(
		debounce(setFiltroPesoGte, 400),
		[]
	)

	useEffect(() => {
		if (!loading && pesoGte !== filtros.pesoGte) {
			debouceFiltroPesoGte(pesoGte)
		}
	}, [pesoGte])
	
	const setFiltroPesoLte = (str) => {
		setFiltros(prev => ({
			...prev,
			pesoLte: str
		}))
	}

	const debouceFiltroPesoLte = React.useCallback(
		debounce(setFiltroPesoLte, 400),
		[]
	)

	useEffect(() => {
		if (!loading && pesoLte !== filtros.pesoLte) {
			debouceFiltroPesoLte(pesoLte)
		}
	}, [pesoLte])


	useEffect(() => {
		if (configLoaded) {
			setCambioFiltros(true)
			fetchDatos(1, cancelToken)
		}
	}, [filtros])

	const handleChangeFiltrosPropietario = (value) => {
		setFiltros(prev => ({
			...prev,
			propietarioId: value
		}))
	}

	const handleChangeFiltrosEstablecimiento = (value) => {
		setFiltros(prev => ({
			...prev,
			establecimientoId: value
		}))
	}

	const handleChangeFiltrosCategoria = (value) => {
		setFiltros(prev => ({
			...prev,
			categoriaId: value
		}))
	}

	const handleChangeFiltros = (valor, cual) => {
		setFiltros(prev => ({
			...prev,
			[cual]: valor
		}))
	}

	const handleCambioOrden = (orden) => {
		handleChangeFiltros({ ...orden }, 'orden')
	}

	useEffect(() => {
		if (!loading) {
			fetchDatos(paginaAct, cancelToken)
		}
	}, [paginaAct, fetchDeACuantos])

	const onChangeFechas = (fechaInicio, fechaFin) => {
		setFiltros(prev => ({
			...prev,
			fecha: {
				inicio: fechaInicio,
				fin: fechaFin
			}
		}))
	}

	const prepareFiltros = () => {
		const copy = {
			...filtros
		}

		if (copy.propietarioId.length) {
			copy.propietarioId = copy.propietarioId.map(elem => elem.value)
		}

		if (copy.establecimientoId.length) {
			copy.establecimientoId = copy.establecimientoId.map(elem => elem.value)
		}

		if (copy.categoriaId.length) {
			copy.categoriaId = copy.categoriaId.map(elem => elem.value)
		}

		return copy
	}

	const fetchDatos = async (pagina, cancelToken) => {
		setLoading(true)
		try {
			const filtrosServer = prepareFiltros(filtros)

			const { datos: respuesta, error } = await api.post('u/reportes/pesajes', {
				cuantos: fetchDeACuantos,
				desde: pagina === 1 ? 0 : (pagina - 1) * fetchDeACuantos,
				filtros: filtrosServer
			}, { cancelToken: cancelToken.token })
			if (!error) {
				setPaginaAct(pagina)
				setDatos(respuesta.pesajes.map(pesaje => {
					const e = { ...pesaje }

					return e
				}))
				setNumResultados(respuesta.numResultados)
			}
		} catch (error) {
			if (!axios.isCancel(error)) {
				console.log('get datos reporte error', error)
			}
		} finally {
			setLoading(false)
			setCambioFiltros(false)
		}
	}

	const linkFormatter = (data) => {
		let content = data.numero

		if (data.url !== null) {
			const href = getUpladsUrl() + data.url
			content = <a href={href} target="_blank">{data.numero}</a>
		}

		return content
	}

	// render
	return (
		<Container fluid id="reporte-pesajes" className="vh-100">
			{/* Header */}
			<Row>
				<Col className="mt-2">
					<h1>Reporte de pesajes</h1>
					<hr />
				</Col>
			</Row>
			<Row>
				<Col>
					<FiltrosFormWrapper>
						<Row className="vw-100">
							<Row>
								<Col md={4} lg={3}>
									<Form.Group className="mb-3" controlId="filtro-propietarioId">
										<Form.Label>Propietarios</Form.Label>
										<MultiSelectCustom
											options={propietariosOptions}
											value={filtros.propietarioId}
											onChange={handleChangeFiltrosPropietario}
											size="sm"
										/>
									</Form.Group>
								</Col>
								<Col md={4} lg={3}>
									<Form.Group className="mb-3" controlId="filtro-establecimientoId">
										<Form.Label>Establecimientos</Form.Label>
										<MultiSelectCustom
											options={establecimientosOptions}
											value={filtros.establecimientoId}
											onChange={handleChangeFiltrosEstablecimiento}
											size="sm"
										/>
									</Form.Group>
								</Col>
								<Col md={4} lg={3}>
									<Form.Group className="mb-3" controlId="filtro-categoriaId">
										<Form.Label>Categoría</Form.Label>
										<MultiSelectCustom
											options={categoriasOptions}
											value={filtros.categoriaId}
											onChange={handleChangeFiltrosCategoria}
											size="sm"
											overrideStrings={{
												allItemsAreSelected: 'Todas',
												selectAll: 'Todas'
											}}
										/>
									</Form.Group>
								</Col>
								{/*
								<Col md={4} lg={3}>
									<Form.Group className="mb-3" controlId="filtro-lote">
										<Form.Label>Lote</Form.Label>
										<Form.Control
											type="text"
											size="sm"
											value={loteNombre}
											onChange={(e) => setLoteNombre(e.target.value)}
										/>
									</Form.Group>
								</Col>
								*/}
							</Row>
							<Row>
								<Col sm={6} md={2}>
									<Form.Group className="mb-3 d-flex flex-row gap-2 align-items-center" controlId="filtro-pesoGte">
										<Form.Label className="mb-0">Más de</Form.Label>
										<Form.Control
											type="number"
											size="sm"
											value={pesoGte}
											onChange={(e) => setPesoGte(e.target.value)}
											style={{ width: 80 }}
										/>
										<Form.Label className="mb-0">kg</Form.Label>
									</Form.Group>
								</Col>
								<Col md={4} lg={3}>
									<Form.Group className="mb-3 d-flex flex-row gap-2" controlId="filtro-pesoLte">
										<Form.Label className="mb-0">Menos de</Form.Label>
										<Form.Control
											type="number"
											size="sm"
											value={pesoLte}
											onChange={(e) => setPesoLte(e.target.value)}
											style={{ width: 80 }}
										/>
										<Form.Label className="mb-0">kg</Form.Label>
									</Form.Group>
								</Col>
							</Row>
						</Row>
					</FiltrosFormWrapper>
				</Col>
			</Row>
			{/*
			<Row className="mt-2">
				<Col>
					<div className="border d-inline-block p-3"><big>Total ingresados: {cambioFiltros ? (<span className="ms-2"><LoadingSpinner size='sm' /></span>) : numberWithSeparator('' + totalIngresados)}</big></div>
				</Col>
			</Row>
			*/}
			<Row className="mt-2">
				<Col>
					{cambioFiltros ? (
						<span className="ms-2"><LoadingSpinner size='sm' /></span>
					) : (
						<small>Mostrando {resultadoDesde} al {resultadoHasta} de un total de <strong>{numResultados}</strong></small>
					)}
				</Col>
			</Row>
			{!datos.length && !loading ? (
				<Alert variant="info">No hay resultados</Alert>
			) : (
				<>
					<Row>
						<Col>
							<SortableTable
								datos={datos}
								cambioOrden={handleCambioOrden}
								columns={
									[
										{
											label: 'Núm. Chip',
											key: 'numChip',
											dataFormatter: (data) => { return !data ? '-' : data }
										},
										{
											label: 'Núm. Caravana',
											key: 'numCaravana',
											sorted: 'asc'
										},
										{
											label: 'Carimbo',
											key: 'carimbo'
										},
										{
											label: 'Raza',
											key: 'raza'
										},
										{
											label: 'Categoría',
											key: 'categoriaNombre'
										},
										{
											label: 'Sexo',
											key: 'sexo'
										},
										{
											label: 'Peso inicial',
											key: 'pesoInicial'
										},
										{
											label: 'Último peso',
											key: 'ultimoPeso'
										},
										{
											label: 'Fecha último pesaje',
											key: 'fechaUltimoPesaje',
											dataFormatter: (data) => data ? getShortDisplayDate(data) : '-'
										},
										{
											label: <span>Último GDP <InfoIcon placement="top"><strong>Última Ganancia Diaria de Peso.</strong><br />La ganancia de peso diaria en kg, desde el penúltimo al último pesaje.</InfoIcon></span>,
											key: 'ultimaGananciaDiaria'
										},
										{
											label: 'Ganancia total',
											key: 'gananciaTotal'
										},
									]
								}
								ordenInicial={filtros.orden}
								loading={loading}
							/>
						</Col>
					</Row>
					<Row>
						<Col>
							<Paginacion
								pagina={paginaAct}
								numPaginas={Math.ceil(numResultados / fetchDeACuantos)}
								setPagina={setPaginaAct}
							/>
						</Col>
						<Col className="text-end">
							<small>
								Mostrar de a
								<Form.Group className="mb-3 d-inline-block mx-2" controlId="de-a-cuantos">
									<Form.Select
										size='sm'
										value={fetchDeACuantos}
										onChange={(e) => setFetchDeACuantos(e.target.value)}
									>
										{opcionesDeACuantos.map((num) => {
											return (
												<option value={num} key={`cuantos-${num}`}>
													{num}
												</option>
											)
										})}
									</Form.Select>
								</Form.Group>
								resultados.
							</small>
						</Col>
					</Row>
				</>
			)}
		</Container>
	)
}
