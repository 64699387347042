import React, { useState, useEffect } from 'react'
import Col from 'react-bootstrap/Col'
import Row from 'react-bootstrap/Row'
import Button from 'react-bootstrap/Button'
import Form from 'react-bootstrap/Form'
import Table from 'react-bootstrap/Table'
import Alert from 'react-bootstrap/Alert'
import Offcanvas from 'react-bootstrap/Offcanvas'
import { PanelHeader } from 'components/Panel'
import axios from 'axios'
import { toast } from 'react-toastify'
import api from 'api/api'
import { LoadingSpinner } from 'components/LoadingSpinner'
import { CATEGORIAS_CON_SEXO_FIJO, FETCH_DE_A_CUANTOS } from 'config/stock'
import Paginacion from 'components/Paginacion'
import { debounce } from 'utils/debounce'
import FichaAnimal from 'components/FichaAnimal/FichaAnimal'
import { FiltrosFormWrapper } from 'components/FiltrosFormWrapper'
import { TIPOS_IDENTIFICACION } from 'config/generales'

export default function ListaAnimalesPanel(props) {
	const { onClose, categoria, propietarios, establecimientos } = props

	const [loading, setLoading] = useState(true)
	const [animales, setAnimales] = useState([])
	const [error, setError] = useState(null)
	const [cancelToken, setCancelToken] = useState(null)
	const [fetchDeACuantos, setFetchDeACuantos] = useState(FETCH_DE_A_CUANTOS)
	const [numResultados, setNumResultados] = useState(0)
	const [paginaAct, setPaginaAct] = useState(1)
	const [showFichaAnimal, setShowFichaAnimal] = useState(null)

	const tiposIdentificacion = TIPOS_IDENTIFICACION

	let selectSexoValue = ''
	if (CATEGORIAS_CON_SEXO_FIJO[categoria.filtros.categoriaId] !== undefined) {
		selectSexoValue = CATEGORIAS_CON_SEXO_FIJO[categoria.filtros.categoriaId]
	} else if (categoria.filtros.sexo !== undefined) {
		selectSexoValue = categoria.filtros.sexo
	}

	const [filtrosInternos, setFiltrosInternos] = useState({
		lote: '',
		raza: '',
		sexo: selectSexoValue,
		color: '',
		carimbo: '',
		tipoIdentificador: 'caravana',
		numIdentificador: ''
	})

	const filtrosServer = { ...categoria.filtros }
	for (const key in filtrosInternos) {
		if (filtrosInternos[key].trim() !== '') {
			filtrosServer[key] = ['like', filtrosInternos[key]]
		}
	}

	delete (filtrosServer.initialized)
	switch (filtrosInternos.tipoIdentificador) {
		case 'chip':
			filtrosServer.numChip = filtrosServer.numIdentificador
			break
		case 'caravana':
			filtrosServer.numCaravana = filtrosServer.numIdentificador
			break
		case 'tatuaje':
			filtrosServer.numTatuaje = filtrosServer.numIdentificador
			break
		default:
			break
	}
	delete (filtrosServer.tipoIdentificador)
	delete (filtrosServer.numIdentificador)

	const fetchAnimales = async (filtros) => {
		try {
			setLoading(true)
			const { datos: respuesta, error } = await api.post('u/ver-animales', {
				cuantos: fetchDeACuantos,
				desde: paginaAct === 1 ? 0 : (paginaAct - 1) * fetchDeACuantos,
				filtros
			})
			if (!error) {
				setPaginaAct(paginaAct)
				setAnimales(respuesta.animales)
				setNumResultados(respuesta.numResultados)
			}
		} catch (error) {
			console.log('Fetch animales error', error)
		}

		setLoading(false)
	}

	useEffect(() => {
		if (!loading) {
			fetchAnimales(filtrosServer)
		}
	}, [])

	useEffect(() => {
		if (!loading) {
			fetchAnimales(filtrosServer)
		}
	}, [paginaAct])

	useEffect(() => {
		setAnimales([])
		fetchAnimales(filtrosServer)
	}, [categoria])

	const handleChange = (valor, cual) => {
		setFiltrosInternos(prev => ({
			...prev,
			[cual]: valor
		}))
	}

	const close = () => {
		onClose()
	}

	const verFicha = (animal) => {
		console.log('viendo ficha de', animal)
		setShowFichaAnimal(animal)
	}

	const saveFichaAnimal = (datos) => {
		console.log('guardando ficha', datos)
	}

	const closeFichaAnimal = () => {
		setShowFichaAnimal(null)
	}

	const debounced = React.useCallback(
		debounce(fetchAnimales, 400),
		[]
	)

	useEffect(() => {
		debounced(filtrosServer)
	}, [filtrosInternos])

	return (
		<Offcanvas id="stock-lista-animales" show={true} onHide={close} placement="end" scroll="true" responsive="lg">
			<PanelHeader buttonProps={{ onClick: close }} text="Lista animales" />
			<Offcanvas.Body>
				<FiltrosFormWrapper>
					<Form.Group className="mb-3" controlId="filtro-lote">
						<Form.Label>Lote</Form.Label>
						<Form.Control
							size='sm'
							value={filtrosInternos.lote}
							onChange={(e) => handleChange(e.target.value, 'lote')}
						/>
					</Form.Group>
					<Form.Group className="mb-3" controlId="filtro-raza">
						<Form.Label>Raza</Form.Label>
						<Form.Control
							size='sm'
							value={filtrosInternos.raza}
							onChange={(e) => handleChange(e.target.value, 'raza')}
						/>
					</Form.Group>
					<Form.Group className="mb-3" controlId="filtro-sexo">
						<Form.Label>Sexo</Form.Label>
						<Form.Select
							size='sm'
							value={filtrosInternos.sexo}
							disabled={selectSexoValue}
							onChange={(e) => handleChange(e.target.value, 'sexo')}
						>
							<option value="">Macho y hembra</option>
							<option value="hembra">Hembra</option>
							<option value="macho">Macho</option>
						</Form.Select>
					</Form.Group>
					<Form.Group className="mb-3" controlId="filtro-color">
						<Form.Label>Color</Form.Label>
						<Form.Control
							size='sm'
							value={filtrosInternos.color}
							onChange={(e) => handleChange(e.target.value, 'color')}
						/>
					</Form.Group>
					<Form.Group className="mb-3" controlId="filtro-carimbo">
						<Form.Label>Carimbo</Form.Label>
						<Form.Control
							size='sm'
							value={filtrosInternos.carimbo}
							onChange={(e) => handleChange(e.target.value, 'carimbo')}
						/>
					</Form.Group>
					<Form.Group className="mb-3" controlId="seleccion-animales-tipoIdentificador">
						<Form.Label>Tipo de identificador</Form.Label>
						<Form.Select
							size="sm"
							onChange={(e) => handleChange(e.target.value, 'tipoIdentificador')}
							value={filtrosInternos.tipoIdentificador}
						>
							{tiposIdentificacion.map((item, index) => {
								return (
									<option value={item.toLowerCase()} key={`tipo-identificacion-${index}`}>
										{item}
									</option>
								)
							})}
						</Form.Select>
					</Form.Group>
					<Form.Group className="mb-3" controlId="filtro-identificador">
						<Form.Label># Identificador</Form.Label>
						<Form.Control
							size='sm'
							value={filtrosInternos.numIdentificador}
							onChange={(e) => handleChange(e.target.value, 'numIdentificador')}
						/>
					</Form.Group>
				</FiltrosFormWrapper>
				{loading && (
					<Row className="mt-2">
						<Col>
							<LoadingSpinner />
						</Col>
					</Row>
				)}
				{animales.length ? (
					<>
						<Row className="mt-2">
							<Col>
								<div className="text-muted">Mostrando animales {(paginaAct - 1) * fetchDeACuantos} - {(paginaAct - 1) * fetchDeACuantos + animales.length} de {numResultados}</div>
								<Table striped bordered hover>
									<thead>
										<tr>
											<th style={{ width: 80 }}># Car</th>
											<th>Lote</th>
											<th>Carimbo</th>
											<th>Raza</th>
											<th>Sexo</th>
											<th>Color</th>
											<th>Peso</th>
											<th></th>
										</tr>
									</thead>
									<tbody>
										{animales.map(animal => {
											return (
												<tr key={`animal-${animal.id}`}>
													<td>{animal.numCaravana ?? '-'}</td>
													<td>{animal.lote}</td>
													<td>{animal.carimbo}</td>
													<td>{animal.raza}</td>
													<td>{animal.sexo}</td>
													<td>{animal.color}</td>
													<td>{animal.peso ?? '-'}</td>
													<td>
														<Button
															size='sm'
															variant="primary"
															onClick={() => verFicha(animal)}
														>
															Ficha
														</Button>
													</td>
												</tr>
											)
										})}
									</tbody>
								</Table>
							</Col>
						</Row>
						<Row>
							<Col>
								<Paginacion
									pagina={paginaAct}
									numPaginas={Math.ceil(numResultados / fetchDeACuantos) || 1}
									setPagina={setPaginaAct}
								/>
							</Col>
						</Row>
					</>
				) : (
					<Alert variant="info">No hay resultados</Alert>
				)}
			</Offcanvas.Body>
			{showFichaAnimal && (
				<FichaAnimal
					animal={showFichaAnimal}
					onSave={saveFichaAnimal}
					onCancel={closeFichaAnimal}
					propietarios={propietarios}
					establecimientos={establecimientos}
				/>
			)}
		</Offcanvas>
	)
}